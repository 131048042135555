<template>
    <div>
        <nav
            class="navbar navbar-expand-md navbar-light top-navbar m-0"
            id="topbar-content"
        >
            <div class="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a href="#" class="navbar-brand">Dashboard</a>
                        </li>
                        <li class="breadcrumb-item d-flex justify-content-center align-items-center">
                            <a href="#" @click.prevent="$router.push({ name: 'builder'})" class="navbar-brand">Theme Builder</a>
                        </li>
                        <li
                            class="breadcrumb-item active d-flex justify-content-center align-items-center"
                            aria-current="page"
                        >
                            <span v-if="themeId">Edit theme</span>
                            <span v-else>Create theme</span>
                        </li>
                    </ol>
                </nav>
            </div>
        </nav>

        <div class="container">
            <div class="header">
                <div class="header-body">
                <div class="row align-items-center">
                    <div class="col">
                    <h6 class="header-pretitle">
                        Create New Theme
                    </h6>
                    <h1 class="header-title">
                        Themes
                    </h1>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-primary btn-sm" v-if="!themeId" @click="saveTheme">Create</button>
                        <button class="btn btn-primary btn-sm" v-else @click="saveTheme">Update</button>
                    </div>
                </div>
                
                </div>
            </div>

            <div style="height: calc( 100vh - 200px ); overflow-x: scroll;">
              <div class="row">

                <div class="col-12">
                  <h6 class="header-pretitle">
                    General
                  </h6>
                </div>

                <div class="col-6">
                  <b-form-group
                      id="input-group-1"
                      label="Theme Name"
                      label-for="input-1"
                  >
                    <b-form-input
                        id="input-1"
                        v-model="themeLocal.name"
                        type="email"
                        placeholder="Enter Theme Name"
                        size="sm"
                        required
                    ></b-form-input>
                  </b-form-group>

                  <div class="form-group">
                    <small class="form-text text-muted">
                      Visibility of the theme
                    </small>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-switch">
                          <input v-model="themeLocal.isPublic" type="checkbox" class="custom-control-input" id="switchOne">
                          <label class="custom-control-label" for="switchOne"></label>
                        </div>
                      </div>
                      <div class="col">
                        <small class="text-muted" v-if="themeLocal.isPublic">Visible</small>
                        <small class="text-muted" v-else>Hidden</small>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="row">
                    <div class="col-auto">
                      <div class="avatar avatar-xxl" v-if="themeLocal.coverImage">
                        <img :src="themeLocal.coverImage.preview" class="avatar-img rounded">
                      </div>
                      <div class="image-placeholder" v-else>
                      </div>
                    </div>
                    <div class="col">
                      <button v-if="!themeLocal.coverImage" class="btn btn-white" @click="openModal({id:'mediaModal', multiple:true, model: 'coverImage'})">
                        <span class="fe fe-image"></span>
                        {{$t('Set cover image')}}
                      </button>
                      <button v-else class="btn btn-white" @click="removeBgImage('coverImage')">
                        <span class="fe fe-trash-2"></span>
                        {{$t('Remove cover image')}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row">

                <div class="col-12">
                  <h6 class="header-pretitle">
                    Body Style
                  </h6>
                </div>

                <div class="col-6">
                  <table class="table table-sm table-hover">
                    <thead>
                    <tr>
                      <th scope="col">CSS Property</th>
                      <th scope="col">Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(property, index) in themeLocal.style.bg" :key="index + 'bg'">
                      <th>{{index}}</th>
                      <td>
                        <b-form-input
                            v-if="property.type == 'text'"
                            v-model="property.value"
                            type="text"
                            size="sm"
                        ></b-form-input>
                        <b-form-select
                            v-if="property.type == 'select'"
                            v-model="property.value"
                            :options="selectOptions[index]"
                            size="sm"
                        ></b-form-select>
                        <div v-else-if="property.type == 'color'">
                          <b-input-group>
                            <b-form-input
                                size="sm"
                                type="text"
                                v-model="property.value.hex"
                                readonly
                            ></b-form-input>
                            <b-input-group-append>
                              <button
                                  :id="`popover-target-bg-${index + 'bg'}`"
                                  class="btn btn-white"
                                  :style="property.value.hex ? 'background-color:' + property.value.hex : ''"
                              ></button>
                            </b-input-group-append>
                          </b-input-group>
                          <b-popover :target="`popover-target-bg-${index + 'bg'}`" triggers="hover" placement="top">
                            <sketch-picker
                                style="box-shadow: none;"
                                v-model="property.value"
                            />
                          </b-popover>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-6">
                  <div class="row mt-5">
                    <div class="col-auto">
                      <div class="avatar avatar-xxl" v-if="themeLocal.bgDesktopImage">
                        <img :src="themeLocal.bgDesktopImage.preview" class="avatar-img rounded">
                      </div>
                      <div class="image-placeholder" v-else>
                      </div>
                    </div>
                    <div class="col">
                      <button v-if="!themeLocal.bgDesktopImage" class="btn btn-white" @click="openModal({id:'mediaModal', multiple:true, model: 'bgDesktopImage'})">
                        <span class="fe fe-image"></span>
                        {{$t('Set desktop bg image')}}
                      </button>
                      <button v-else class="btn btn-white" @click="removeBgImage('bgDesktopImage')">
                        <span class="fe fe-trash-2"></span>
                        {{$t('Remove desktop image')}}
                      </button>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-auto">
                      <div class="avatar avatar-xxl" v-if="themeLocal.bgMobileImage">
                        <img :src="themeLocal.bgMobileImage.preview" class="avatar-img rounded">
                      </div>
                      <div class="image-placeholder" v-else>
                      </div>
                    </div>
                    <div class="col">
                      <button v-if="!themeLocal.bgMobileImage" class="btn btn-white" @click="openModal({id:'mediaModal', multiple:true, model: 'bgMobileImage'})">
                        <span class="fe fe-image"></span>
                        {{$t('Set mobile bg image')}}
                      </button>
                      <button v-else class="btn btn-white" @click="removeBgImage('bgMobileImage')">
                        <span class="fe fe-trash-2"></span>
                        {{$t('Remove mobile image')}}
                      </button>
                    </div>
                  </div>
                </div>

              </div>
              <div class="row mt-5">

                <div class="col-6">
                  <h6 class="header-pretitle">
                    Link Style
                  </h6>

                  <table class="table table-sm table-hover">
                    <thead>
                    <tr>
                      <th scope="col">CSS Property</th>
                      <th scope="col">Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(property, index) in themeLocal.style.link" :key="index + 'link'">
                      <th>{{index}}</th>
                      <td>
                        <b-form-input
                            v-if="property.type == 'text'"
                            v-model="property.value"
                            type="text"
                            size="sm"
                        ></b-form-input>
                        <b-form-select
                            v-if="property.type == 'select'"
                            v-model="property.value"
                            :options="selectOptions[index]"
                            size="sm"
                        ></b-form-select>
                        <div v-else-if="property.type == 'color'">
                          <b-input-group>
                            <b-form-input
                                size="sm"
                                type="text"
                                v-model="property.value.hex"
                                readonly
                            ></b-form-input>
                            <b-input-group-append>
                              <button
                                  :id="`popover-target-bg-${index + 'link'}`"
                                  class="btn btn-white"
                                  :style="property.value.hex ? 'background-color:' + property.value.hex : ''"
                              ></button>
                            </b-input-group-append>
                          </b-input-group>
                          <b-popover :target="`popover-target-bg-${index + 'link'}`" triggers="hover" placement="top">
                            <sketch-picker
                                style="box-shadow: none;"
                                v-model="property.value"
                            />
                          </b-popover>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-6">
                  <h6 class="header-pretitle">
                    Social Link Style
                  </h6>
                  <table class="table table-sm table-hover">
                    <thead>
                    <tr>
                      <th scope="col">CSS Property</th>
                      <th scope="col">Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(property, index) in themeLocal.style.socialLink" :key="index + 'socialLink'">
                      <th>{{index}}</th>
                      <td>
                        <b-form-input
                            v-if="property.type == 'text'"
                            v-model="property.value"
                            type="text"
                            size="sm"
                        ></b-form-input>
                        <b-form-select
                            v-if="property.type == 'select'"
                            v-model="property.value"
                            :options="selectOptions[index]"
                            size="sm"
                        ></b-form-select>
                        <div v-else-if="property.type == 'color'">
                          <b-input-group>
                            <b-form-input
                                size="sm"
                                type="text"
                                v-model="property.value.hex"
                                readonly
                            ></b-form-input>
                            <b-input-group-append>
                              <button
                                  :id="`popover-target-bg-${index + 'socialLink'}`"
                                  class="btn btn-white"
                                  :style="property.value.hex ? 'background-color:' + property.value.hex : ''"
                              ></button>
                            </b-input-group-append>
                          </b-input-group>
                          <b-popover :target="`popover-target-bg-${index + 'socialLink'}`" triggers="hover" placement="top">
                            <sketch-picker
                                style="box-shadow: none;"
                                v-model="property.value"
                            />
                          </b-popover>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
        </div>

        <media-modal
            id="mediaModal"
            category="profile"
            :action="useImage"
            size="full"
            :data="model"
        ></media-modal>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { modalMixins } from "@/mixins/modal.js";

import MediaModal from "@/components/MediaModal.vue";
import ButtonLoading from "@/components/ButtonLoading";
import { Sketch } from "vue-color";

export default {
    mixins: [modalMixins],
    components: {
        MediaModal,
        ButtonLoading,
        'sketch-picker': Sketch,
    },

    data() {
        return {
            themeLocal: {
                name: '',
                coverImage: '',
                bgDesktopImage: '',
                bgMobileImage: '',
                style: {
                    bg: {
                        'color': {
                            type: 'text',
                            value: ''
                        },
                        'background': {
                            type: 'text',
                            value: ''
                        },
                        'background-color': {
                            type: 'text',
                            value: ''
                        },
                        'background-repeat': {
                            type: 'select',
                            value: ''
                        },
                        'background-position': {
                            type: 'select',
                            value: ''
                        },
                        'background-size': {
                            type: 'select',
                            value: ''
                        },
                    },
                    link: {
                        'color': {
                            type: 'text',
                            value: ''
                        },
                        'font-weight': {
                            type: 'text',
                            value: ''
                        },
                        'border': {
                            type: 'text',
                            value: ''
                        },
                        'border-radius': {
                            type: 'text',
                            value: ''
                        },
                        'background': {
                            type: 'text',
                            value: ''
                        },
                        'background-color': {
                            type: 'text',
                            value: ''
                        },
                        'box-shadow': {
                            type: 'text',
                            value: ''
                        }
                    },
                    socialLink: {
                        'color': {
                            type: 'text',
                            value: ''
                        },
                        'font-size': {
                            type: 'text',
                            value: ''
                        },
                        'border': {
                            type: 'text',
                            value: ''
                        },
                        'border-radius': {
                            type: 'text',
                            value: ''
                        },
                        'background': {
                            type: 'text',
                            value: ''
                        },
                        'background-color': {
                            type: 'text',
                            value: ''
                        },
                        'box-shadow': {
                            type: 'text',
                            value: ''
                        }
                    }
                },
                userId: '',
                isPublic: true,
            },
            selectOptions: {
                'background-repeat': ['repeat', 'repeat-x', 'repeat-y', 'no-repeat', 'space', 'round'],
                'background-position': ['left top', 'left center', 'left bottom', 'right top', 'right center', 'right bottom', 'center top', 'center center', 'center bottom'],
                'background-size': ['auto', 'cover', 'contain'],
            },
            model: null,
            themeId: this.$route.params.themeId,
        };
    },
    computed: {
        ...mapState("auth", {
            user: "payload",
        }),
        ...mapGetters('themes', {
            getThemeInStore: 'find',
        }),
        theme() {
            return this.getThemeInStore().data[0];
        }
    },
    created() {
        if (this.themeId) {
            this.get(this.themeId).then( res => {
                this.themeLocal = res
            })
            .catch( e => {
                console.log(e)
            })
        }
    },

    methods: {
        ...mapActions("themes", ['create', 'find', 'patch', 'remove', 'get', 'update']),
        useImage(image, index) {
            this.themeLocal[index] = image;
        },
        saveTheme() {
            let theme = this.themeLocal;
            // console.log(theme)
            theme.userId = this.user.userId;
            let params = {$unset: {}};
            if ( !theme.coverImage ) {
                theme.coverImage = null
                params.$unset = {coverImg: ''}
            }
            if ( !theme.bgDesktopImage ) {
                theme.bgDesktopImage = null
                params.$unset = {bgDesktopImage: ''}
            }
            if ( !theme.bgMobileImage ) {
                theme.bgMobileImage = null
                params.$unset = {bgMobileImage: ''}
            }

            if ( this.themeId ) {
                this.update([this.themeId, theme, {params}])
                    .then((res) => {
                        this.$toasted.global.success({
                            message: this.$t("Theme has been updated"),
                        });
                    })
                    .catch((error) => {
                        this.$toasted.global.error({
                            message: `${error.message}`,
                        });
                        console.log(error)
                    });
            } else {
                this.create([theme])
                    .then((res) => {
                        this.$toasted.global.success({
                            message: this.$t("Theme has been created"),
                        });
                        this.$router.push({ name: 'builder-edit', params: { themeId: res._id } })
                    })
                    .catch((error) => {
                        this.$toasted.global.error({
                            message: `${error.message}`,
                        });
                        console.log(error)
                    });
            }
        },
        removeBgImage(index) {
            this.themeLocal[index] = '';
        },
    },
};
</script>

<style lang="scss" scoped>
// .removeBgImage {
//     position: absolute;
//     bottom: 0;
//     right: 0;
//     z-index: 2;
// }
// .dropzone .dz-preview {
//     margin: auto;
// }
// .dz-preview {
//     img {
//         cursor: pointer;
//         object-fit: cover;
//         width: 100%;
//         height: 100%;
//         border-radius: 0.375rem;
//     }
// }
// .dropzone {
//     position: relative !important;
//     display: flex;
//     flex-direction: column;
//     min-height: auto;
//     border: none;
//     background: transparent;
//     padding: 0;
// }
// .dz-preview-single {
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     border-radius: 0.375rem;
//     cursor: pointer;
// }
// .dropzone .dz-preview:hover {
//     z-index: 1!important;
// }
// .dz-message {
//     &:hover {
//         border-color: #95aac9;
//         color: #12263f;
//     }
//     padding: 5rem 1rem;
//     background-color: #fff;
//     border: 1px dashed #d2ddec;
//     border-radius: 0.375rem;
//     text-align: center;
//     color: #95aac9;
//     transition: all 0.2s ease-in-out;
//     order: -1;
//     cursor: pointer;
//     z-index: -1;
//     margin: 10px 0;
// }
// .dz-button {
//     background: 0 0;
//     border: 0;
//     font-size: inherit;
//     color: inherit;
// }
.popover {
    max-width: 250px;
    padding: 5px;
}
.image-placeholder {
    height: 128px;
    width: 128px;
    border: 1px solid #D2DDEC;
}
</style>